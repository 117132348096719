window._ = require('lodash');
window.$ = require('jquery');
window.moment = require('moment');
window._ = require('lodash');
const bootstrap = require('bootstrap');
// var Isotope = require('isotope-layout');
// window._ = require('isotope-layout');

// var iso = new Isotope('.grid', {
//   layoutMode: 'packery'
// });

// import '../frontend/assets/vendor/purecounter/purecounter_vanilla.js';
// import '../frontend/assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
// import '../frontend/assets/vendor/glightbox/js/glightbox.min.js';
// import '../frontend/assets/vendor/swiper/swiper-bundle.min.js';
// import '../frontend/assets/vendor/waypoints/noframework.waypoints.js';
// import '../frontend/assets/vendor/php-email-form/validate.js';
// import '../frontend/assets/js/main.js';